import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const BlogPost = ({ month, date, image, title, url }) => {

  return (
      <>
          <a href={url} title={title} target="_blank">
            <div className="blog-post">
                <div className="blog-img">
                    {/*<div className="data">*/}
                    {/*<span>{date}</span>*/}
                    {/*<small>{month}</small>*/}
                    {/*</div>*/}

                          <img src={image} alt={title}></img>
                </div>

                <div className="blog-info">
                    <h6>{title}</h6>
                    <div className="btn-bar">Read More</div>
                </div>
            </div>  
          </a>
    </>
  );
};

export default BlogPost;
