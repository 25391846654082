import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      imageName: "nathan-sm",
      desc: `  Dr. Dituri has been a godsend for our school. By coaching me as an instructional leader, by working with our math department, and by leading two daylong professional-development events here, he has helped us increase rigor in our curriculum, prioritize problem-solving in our lessons, and strengthen groupwork in our classrooms. He has made us more meticulous in our planning. When I surveyed them anonymously, our teachers unanimously praised their experiences with him. I wholeheartedly recommend Dr. Dituri. I'm certain that he will be a valuable asset to any organization.`,
      reviewerName: "Nathan Patton, Director of Curriculum and Instruction at People's Prep Charter School in Newark, NJ",
    },
    {
      imageName: "ellen-sm",
      desc: `  I credit Phil with helping me become the math teacher I am today. I have worked with Phil in multiple capacities - first at NYU's Center for Mathematical Talent, as his student at Fordham University, and as a consultant for my current school. His passion for education was clear when we first met and Phil used games and puzzles to show me that math class is about problem solving and student engagement. In our time working together, his emphasis on clarity, consistency, and routines has allowed me to establish a classroom structure that makes room for students to problem solve, work together, and love math in an authentic way. Without a doubt, Phil's zeal and enthusiasm for math education has influenced my teaching significantly, allowing me to make a positive impact on my school's math culture.`,
      reviewerName: "Ellen Banks, 7th and 8th Grade Math Teacher at Cornelia Connelly Center, NY",
    },
    {
      imageName: "kenneth-sm",
      desc: `  Dr. Dituri has been consulting with our department for several years. During this time, he has provided high-quality professional development and instructional coaching to the teachers and administrators in our schools. This work has targeted teaching practices, standards-based curriculum mapping, assessment, and technology integration for students of all academic levels. He has been a key resource for our staff, especially when adapting instruction during the pandemic and the needs of our students during that time. We have seen great growth and learning throughout our schools as a result of this critical work.`,
      reviewerName: "Kenneth Kroog, Executive Manager at Nassau BOCES, NY",
    },
    {
      imageName: "jaspreet-sm",
      desc: `  I had the privilege of having Phil as my cooperating teacher at New Design High School in 2012. I student taught his 12th grade Mathematical Modeling and Combinatorics classes while pursuing my Mathematics Education degree at Teacher's College, Columbia University. Everything I know about the craft of teaching, I owe it to Phil. Phil put me at ease immediately by ensuring I was known, respected, and valued by everyone in the community - he introduced me to everyone as his "new pair of eyes." As a mentor and coach, Phil is the giver of great advice. As an educator, Phil is par excellence - his energy, his planning, his stories, his ability to add context to all his lessons, his creativity to use "gremlins" in his probability lessons, or games in all his lessons, his capability to exceptionally deliver math concepts and skills, and most importantly the connections he has with his students and colleagues illustrate how remarkably he models an engaging, safe, and collaborative environment where learning will never feel like a task and work will never feel like "work." It's an honor to know Phil, share work space with him, and write this recommendation for him.`,
      reviewerName: "Jaspreet Sethi, Teacher, and Founder of Math with a Smile & Math for Littles",
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div
            className="testmonial-box media"
            key={i}
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <div className="avatar">
              <img
                src={`img/testimonial/${val.imageName}.jpg`}
                alt="review comments"
              ></img>
            </div>

            {/* End avatar */}

            <div className="testmonial-text media-body">
              <p>{val.desc}</p>
              <div className="testmonial-info">
                <h6>{val.reviewerName}</h6>
              </div>
            </div>

            {/* End testimonial-text */}
          </div>
        ))}
      </Slider>
    </>
  );
}
