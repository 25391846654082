import React from "react";

const ServiceContent = [
  {
    icon: "Services 1 Professional Development",
    no: "01",
    service: "PROFESSIONAL DEVELOPMENT",
    desc: `Design custom professional development to meet the varied needs of schools and organizations`,
    animationDelay: "",
  },
  {
    icon: "Services 2 Consultation",
    no: "02",
    service: "CONSULTATION",
    desc: `Provide consultation to teachers, school leaders and administrators on a diverse array of educational and pedagogical issues and initiatives`,
    animationDelay: "150",
  },
  {
    icon: "Services 3 academic coaching",
    no: "03",
    service: "ACADEMIC COACHING",
    desc: `Provide individualized academic coaching to new and experienced teachers looking to improve their practice`,
    animationDelay: "300",
  },
  {
    icon: "Services 4 Program Design",
    no: "04",
    service: "PROGRAM DESIGN",
    desc: `Assist administrators in designing educational programs for students, teachers, and stakeholders`,
    animationDelay: "",
  },
  {
    icon: "Services 5 Curriculum",
    no: "05",
    service: "CURRICULUM",
    desc: `Design, analyze, and audit curriculum; map standards`,
    animationDelay: "150",
  },
  {
    icon: "Services 6 Lesson Study",
    no: "06",
    service: "LESSON STUDY",
    desc: `Facilitate an innovative, research-based form of professional development in which teachers work collaboratively to examine a lesson and reflect upon best teaching practices`,
    animationDelay: "300",
  },
];

const ServiceTwoAnimation = () => {
  return (
    <>
      <div className="row gy-4">
        {ServiceContent.map((val, i) => (
          <div
            className="col-sm-6 col-lg-4"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.animationDelay}
          >
            <div className="feature-box-02">
              <div className="icon">
                <img alt={val.icon} src={"img/service/" + val.icon + ".svg"} />
              </div>
              <h6>
                <span>{val.no}.</span> {val.service}
              </h6>
              <p>{val.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ServiceTwoAnimation;
