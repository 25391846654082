import React from "react";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/SliderThreeAnimation";
import About from "../../components/about/AboutLightAnimation";
import Service from "../../components/service/ServiceTwoAnimation";
import Skills from "../../components/skills/SkillsAnimation";
import Portfolio from "../../components/portfolio/PortfolioAnimation";
import Testimonial from "../../components/testimonial/TestimonialAnimation";
import Blog from "../../components/blog/BlogAnimation";
import Contact from "../../components/contact/ContactAnimation";
import Footer from "../../components/footer/FooterAnimation";
import useDocumentTitle from "../../components/useDocumentTitle";

const HomeThree = () => {
    useDocumentTitle("Philip Dituri, Ph.D.");
  return (
    <>
      <Header />
      {/* End Header Section */}

      <Slider />
      {/* End Banner Section */}

      {/* About Me */}
      <section id="about" className="section about-section after-left-section">
        <div className="container">
          <About />
        </div>
      </section>
      {/* End About Me */}

      {/* Services */}
      <section id="services" className="section services-section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">My Services</h3>
              <p>
                I am available for a wide variety of projects and initiatives. <br/>
                While I provide custom services, certain patterns of need arise. <br/>
                Here are some of the ways in which I can help you.
              </p>
            </div>
          </div>
          {/* End .row */}
          <Service />
        </div>
      </section>
      {/* End Services */}

      {/*  Skills */}
      <section className="section skill-section">
        <div className="container">
          <Skills />
        </div>
      </section>
          {/* End Skills */}

        <section id="work" className="section gray-bg">
            <div className="container">
                {/* End .row */}
                <Portfolio />
            </div>
        </section>
        {/* End Portfolio */}

      <div id="testimonial" className="section testimonial">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Testimonials</h3>
            </div>
          </div>
          <Testimonial />
        </div>
      </div>
      {/* End testimonial */}

      {/* Blog */}
      <section id="blog" className="section gray-bg">
        <div className="container">
          <div className="row justify-content-center section-title text-center">
            <div className="col-lg-7">
              <h3 className="font-alt">Recent Media</h3>
            </div>
          </div>
          {/* End .row */}
          <Blog />
        </div>
      </section>
      {/*  End Blog */}

      {/* Contact */}
      <section id="contact" className="section after-left-section above-left">
        <div className="portrait-container">
            <img alt="portrait" src="img/contact/contact-portrait.jpg" />
        </div>
        <div className="container">
          <div className="row align-items-center">

            <div className="col-lg-5 ml-auto my-3">
              <div className="contact-info">
                <div
                  className="sm-title"
                >
                  <h4 className="font-alt">Get in Touch</h4>
                  <p>
                    Email me with inquiries related to project ideas, educational opportunities, and consulting services. Although I am located in New York, I work with clients both nationally and internationally, and would love to connect.
                  </p>
                </div>

                <div
                  className="media"
                >
                  <div className="icon">
                    <i className="ti-map"></i>
                  </div>
                  <span className="media-body">
                    Brooklyn, NY
                  </span>
                </div>
                {/* End media */}

                <div
                  className="media"
                >
                  <div className="icon">
                    <i className="ti-email"></i>
                  </div>
                  <span className="media-body">
                    <a title="email" href="mailto:phil@dituriconsulting.com" target="_blank">phil@dituriconsulting.com</a>
                  </span>
                </div>
                {/* End media */}

              </div>
            </div>
            {/* End .col */}
          </div>
        </div>
      </section>
      {/* End Contact */}

      {/* Footer */}
      <footer className="footer white">
        <div className="container">
          <Footer />
        </div>
      </footer>
      {/*  End Footer */}
    </>
  );
};

export default HomeThree;
