import React from "react";

const Skills = () => {
  return (
    <>
      <div className="row">
        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="sm-title">
            <h4 className="font-alt">Education</h4>
          </div>

          <ul className="aducation-box theme-bg add-padding">
            <li>
              <span className="dark-bg">2013</span>
              <h6>Ph.D. Mathematics Education</h6>
              <p>Columbia University</p>
            </li>

            <li>
              <span className="dark-bg">2006</span>
              <h6>M.A. Mathematics Education</h6>
              <p>Teacher's College Columbia University</p>
            </li>

            <li>
              <span className="dark-bg">2002</span>
              <h6>B.A. Mathematics</h6>
              <p>New York University, College of Arts and Science</p>
            </li>
          </ul>

          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="sm-title">
            <h4 className="font-alt">Experience</h4>
          </div>
          <ul className="aducation-box dark-bg">
            <li>
              <span className="theme-bg">2018-Present</span>
              <h6>Director of Education</h6>
              <p>Financial Lifecycle Mathematics</p>
            </li>

            <li>
              <span className="theme-bg">2016-2018</span>
              <h6>Visiting Professor of Mathematics Education</h6>
              <p>Fordham University, Graduate School of Education</p>
            </li>

            <li>
              <span className="theme-bg">2003-2016</span>
              <h6>Department Chair / Instructional Coach / Teacher</h6>
              <p>New Design High School, New York City Department of Education</p>
            </li>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div
          className="col-lg-4 ml-auto my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div className="sm-title">
            <h4 className="font-alt">Popular Workshops</h4>
          </div>
          <div className="gray-bg skill-box">
            <ul>
              <li>Collaborative Learning</li>
              <li>Teaching Problem Solving</li>
              <li>Conducting Lesson Study</li>
              <li>Examining & Enhancing Routines</li>
              <li>Examining & Enhancing Best Practices</li>
              <li>Standardized Test Preparation</li>
              <li>Technology Integration</li>
              <li>Calculator Strategies</li>
              <li>Remote Learning</li>
              <li>Financial Literacy & Personal Finance</li>
            </ul>
          </div>
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default Skills;
