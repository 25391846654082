import React from "react";

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div
          className="col-lg-6 my-3"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="img-box">
            <img src="img/about/about-4.jpg" alt="Philip Dituri" />
          </div>
        </div>

        <div
          className="col-lg-6 my-3"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="typo-box about-me">
            <h3>Philip Dituri</h3>
            <p>
                I have taught and inspired educators and children for over 20 years. I am currently an educational consultant and the Director of Education at the not-for-profit Financial Life Cycle Education. I served as a Visiting Professor at Fordham University and was a teacher, instructional coach, and chairperson of the Mathematics Department at New Design High School in Manhattan. While in public school, I was a three-time Math for America Master Teacher and a Big Apple Award finalist. I have a B.A. in Mathematics from NYU and a Ph.D. in Mathematics Education from Columbia University. My research interests include proof and reasoning, problem solving, collaborative learning, personal finance, and remote learning.
            </p>
            <div className="row about-list">
              <div className="col-md-12">
                <div className="media">
                  <img className="icon" alt="cap" src="img/about/AM 20 yrs Education.svg" />
                  <p> 20+ years in education</p>
                </div>
                <div className="media">
                  <img className="icon" alt="chalkboard" src="img/about/AM instructional hours.svg" />
                  <p> 15,000+ hours of instructional time logged</p>
                </div>
                <div className="media">
                  <img className="icon" alt="bulb" src="img/about/AM 100 teachers mentored.svg" />
                  <p> 100+ teachers mentored</p>
                </div>
                <div className="media">
                  <img className="icon" alt="certificate" src="img/about/AM presentations.svg" />
                  <p> 30+ professional presentations at NCTM and other conferences</p>
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
            <div className="btn-bar">
              <a className="px-btn px-btn-theme" href="img/Dituri CV Web.pdf" download>
                Download CV
              </a>
            </div>
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
