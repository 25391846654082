import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import BlogPost from "./BlogPost";

Modal.setAppElement("#root");

const Blog = () => {
    const [bloggerResponse, setResponse] = useState({});
    const [isLoading, setLoading] = useState(true);
    const monthNames = ["Filler", "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];

    useEffect(() => {
        getPosts();
    }, []);

    const getPosts = () => {
        let request = new XMLHttpRequest();
        request.open("GET", "https://www.googleapis.com/blogger/v3/blogs/8029997738914010235/posts?key=AIzaSyCTyfvV_b1hQyL2ly_ghmRHyX79wXG-7zg&fetchImages=true&max-results=3");
        request.send();
        request.onload = () => {
            if (request.status == 200) {
                setResponse(JSON.parse(request.response));
                setLoading(false);
            }
            else {
                return null;
            }
        };
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

  return (
      <>
      <div className="row">
        <div
          className="col-lg-4 col-md-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
            <BlogPost month={monthNames[+bloggerResponse.items[0].published.split("-")[1]]}
                date={bloggerResponse.items[0].published.split("-")[2].substring(0, 2)}
                image={bloggerResponse.items[0].images[0].url}
                title={bloggerResponse.items[0].title}
                url={bloggerResponse.items[0].url}
            />
        </div>

        <div
          className="col-lg-4 col-md-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
            <BlogPost month={monthNames[+bloggerResponse.items[1].published.split("-")[1]]}
                date={bloggerResponse.items[1].published.split("-")[2].substring(0, 2)}
                image={bloggerResponse.items[1].images[0].url}
                title={bloggerResponse.items[1].title}
                url={bloggerResponse.items[1].url}
            />
        </div>

        <div
          className="col-lg-4 col-md-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
            <BlogPost month={monthNames[+bloggerResponse.items[2].published.split("-")[1]]}
                date={bloggerResponse.items[2].published.split("-")[2].substring(0, 2)}
                image={bloggerResponse.items[2].images[0].url}
                title={bloggerResponse.items[2].title}
                url={bloggerResponse.items[2].url}
            />
        </div>
      </div>
      {/* End .row */}
    </>
  );
};

export default Blog;
